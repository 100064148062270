:root {
    font-size: 62.5%;
}
* {
    margin: 0;
    padding: 0;
}
body {
    margin: 0;
    font-family: -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.8rem;
    position: absolute;
    width: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
#root {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 11.7rem auto;
    grid-template-rows: 9.1rem auto;
}

h3 {
    font-size: 1.5rem;
    font-weight: 500;
}
